import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import axiosPortal from '../api/axiosPortal';

const FillForm = () => {
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axiosPortal.get(`/form/${token}`);
        form.setFieldsValue(response.data);
      } catch (error) {
        console.error('Error fetching form data:', error);
        message.error('Error loading form data');
      }
    };

    fetchFormData();
  }, [token, form]);

  const onFinish = async (values) => {
    try {
      await axiosPortal.post(`/form/${token}/submit`, values);
      message.success('Form submitted successfully!');
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="fill-form">
      <h1>Customer Information Form</h1>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone number!' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FillForm;
