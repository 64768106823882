import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TokenValidator from './components/TokenValidator';

// Components
import PortalHeader from './components/PortalHeader';
import PortalLogin from './pages/PortalLogin';

// Pages
import PortalHome from './pages/PortalHome';
import OnboardingForm from './pages/OnboardingForm';
import ViewContract from './pages/ViewContract';
import PortalSupport from './pages/PortalSupport';
import PortalPaymentsHistory from './pages/PortalPaymentsHistory';

// Array of paths that should not display the header
const noHeaderPaths = ['/'];

function AppContent() {
  const location = useLocation();
  const shouldShowHeader = !noHeaderPaths.includes(location.pathname);

  return (
    <div className="app">
      {shouldShowHeader && <PortalHeader />}
      <div className="app-content">
        <Routes>
          <Route path="/" element={<PortalLogin />} />
          <Route path="/home" element={<PortalHome />} />
          <Route path="/support" element={<PortalSupport />} />
          <Route path="/payments" element={<PortalPaymentsHistory />} />
          <Route path="/view-contract/:token" element={<TokenValidator><ViewContract /></TokenValidator>} />
          <Route path="/onboarding-form/:token" element={<TokenValidator><OnboardingForm /></TokenValidator>} />
          {/* Add more routes here as needed */}
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;