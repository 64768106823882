import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Layout } from 'antd';
import axiosPortal from '../api/axiosPortal';
import './PortalLogin.css';

const { Title } = Typography;
const { Content } = Layout;

const Login = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axiosPortal.post('/auth/login', values);
      localStorage.setItem('token', response.data.access_token);
      if (response.data.is_temp_password) {
        setShowChangePassword(true);
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please check your credentials and try again.');
    }
  };

  const handlePasswordChange = async (values) => {
    try {
      await axiosPortal.post('/auth/change-password', { new_password: values.new_password });
      alert('Password changed successfully');
      navigate('/home');
    } catch (error) {
      console.error('Password change error:', error);
      alert('Failed to change password. Please try again.');
    }
  };

  return (
    <Layout className="login-container">
      <Content className="login-content">
        <Title level={2}>{showChangePassword ? 'Change Your Password' : 'Login'}</Title>
        {showChangePassword ? (
          <Form onFinish={handlePasswordChange} layout="vertical">
            <Form.Item
              name="new_password"
              label="New Password"
              rules={[{ required: true, message: 'Please input your new password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Change Password
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        )}
      </Content>
    </Layout>
  );
};

export default Login;
