import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './PortalHeader.css';

const { Header } = Layout;

const PortalHeader = () => {
  const menuItems = [
    { key: '1', label: <Link to="/home">Hjem</Link> },
    { key: '2', label: <Link to="/profile">Profil</Link> },
    { key: '3', label: <Link to="/support">Support</Link> },
    { key: '4', label: <Link to="/payments">Betalinger</Link> },
    { key: '5', label: <Link to="/logout">Log ud</Link> },
  ];

  return (
    <Header className="portal-header">
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={menuItems} />
    </Header>
  );
};

export default PortalHeader;