import React from 'react';
import { Layout, Typography, List, Button } from 'antd';
import './PortalPaymentsHistory.css';

const { Content } = Layout;
const { Title } = Typography;

const dummyInvoices = [
  { id: 1, title: 'Bogføringsassistance', date: '2023-05-01', amount: '5.000 DKK' },
  { id: 2, title: 'Momsindberetning', date: '2023-04-15', amount: '2.500 DKK' },
  { id: 3, title: 'Årsregnskab', date: '2023-03-30', amount: '15.000 DKK' },
  { id: 4, title: 'Lønadministration', date: '2023-03-01', amount: '3.000 DKK' },
];

const PortalPaymentsHistory = () => {
  return (
    <Content className="portal-payments-history">
      <Title level={2}>Betalingshistorik</Title>
      <List
        className="invoice-list"
        itemLayout="horizontal"
        dataSource={dummyInvoices}
        renderItem={(item) => (
          <List.Item
            actions={[<Button type="primary">Se detaljer</Button>]}
          >
            <List.Item.Meta
              title={item.title}
              description={`Dato: ${item.date}`}
            />
            <div className="invoice-amount">{item.amount}</div>
          </List.Item>
        )}
      />
    </Content>
  );
};

export default PortalPaymentsHistory;