import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosPortal from '../api/axiosPortal';

const ViewContract = () => {
  const [contract, setContract] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const response = await axiosPortal.get(`/contract/${token}`);
        setContract(response.data);
      } catch (error) {
        console.error('Error fetching contract:', error);
      }
    };

    fetchContract();
  }, [token]);

  if (!contract) return <div>Loading contract...</div>;

  return (
    <div className="view-contract">
      <h1>Contract Details</h1>
      <h2>{contract.title}</h2>
      <p>Contract ID: {contract.id}</p>
      <p>Start Date: {contract.startDate}</p>
      <p>End Date: {contract.endDate}</p>
      <div dangerouslySetInnerHTML={{ __html: contract.content }} />
    </div>
  );
};

export default ViewContract;