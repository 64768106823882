import React from 'react';
import { Typography, Layout, Card, List, Tag, Button } from 'antd';
import { ExclamationCircleOutlined, CalendarOutlined, HistoryOutlined } from '@ant-design/icons';
import './PortalHome.css';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PortalHome = () => {
  const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('da-DK');

  const upcomingEvents = [
    { title: 'Momsregistrering', date: '15. maj 2023' },
    { title: 'Årsregnskab', date: '30. juni 2023' },
  ];

  const recentEvents = [
    { title: 'Faktura betalt', date: '1. april 2023' },
    { title: 'Kvartalsrapport indsendt', date: '15. marts 2023' },
  ];

  return (
    <Content className="portal-home">
      <div className="portal-home-content">
        <Title level={1}>Velkommen til din kundeportal</Title>
        <Paragraph>
          Her kan du se detaljer om din aftale, se et overblik over dine betalinger, oprette support henvendelser og meget mere.
        </Paragraph>

        <Card
          title={<><ExclamationCircleOutlined /> Handlinger</>}
          className="action-card"
        >
          <Paragraph>
            Din onboarding formular er ikke udfyldt. Venligst udfyld den inden {sevenDaysFromNow}.
          </Paragraph>
          <Button type="primary">Udfyld onboarding formular</Button>
        </Card>

        <Card
          title={<><CalendarOutlined /> Kommende begivenheder</>}
          className="events-card"
        >
          <List
            itemLayout="horizontal"
            dataSource={upcomingEvents}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.date}
                />
              </List.Item>
            )}
          />
        </Card>

        <Card
          title={<><HistoryOutlined /> Seneste aktiviteter</>}
          className="recent-events-card"
        >
          <List
            itemLayout="horizontal"
            dataSource={recentEvents}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.date}
                />
              </List.Item>
            )}
          />
        </Card>
      </div>
    </Content>
  );
};

export default PortalHome;