import React, { useState, useEffect, useRef } from 'react';
import { Layout, Typography, Card, Button, Input, List, Avatar, message, Modal, Tooltip } from 'antd';
import { PhoneOutlined, MailOutlined, SendOutlined, RobotOutlined, CopyOutlined, InfoCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import axiosPortal from '../api/axiosPortal';
import './PortalSupport.css';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const dummyTickets = [
  { id: 1, title: 'Spørgsmål om momsindberetning', status: 'Åben' },
  { id: 2, title: 'Problem med login', status: 'Lukket' },
];

const PortalSupport = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const inputRef = useRef(null);
  const [sourceUrls, setSourceUrls] = useState({});

  const handlePressEnter = (event) => {
    if (!event.shiftKey && inputMessage.trim()) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    createThread();
    fetchSourceUrls();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  const createThread = async () => {
    try {
      const response = await axiosPortal.post('/portalchatbot/create_thread');
      setThreadId(response.data.thread_id);
    } catch (error) {
      console.error('Error creating thread:', error);
      message.error('Kunne ikke oprette chatsamtale');
    }
  };

  const fetchSourceUrls = async () => {
    try {
      const response = await axiosPortal.get('/portalchatbot/get_source_urls');
      setSourceUrls(response.data);
    } catch (error) {
      console.error('Error fetching source URLs:', error);
    }
  };

  const handleSourceClick = (source) => {
    const url = sourceUrls[source];
    if (url) {
      window.open(url, '_blank');
    } else {
      console.warn(`No URL found for source: ${source}`);
    }
  };

  const formatMessage = (text) => {
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      const parts = line.split(/(\*\*.*?\*\*|\[[\d,]+\]|^-\s)/);
      return (
        <div key={lineIndex} className={line.startsWith('- ') ? 'bullet-point' : ''}>
          {parts.map((part, partIndex) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            } else if (part.match(/\[[\d,]+\]/)) {
              return <sup key={partIndex}>{part}</sup>;
            } else if (part === '- ') {
              return null; // Remove the bullet point marker
            } else if (line.startsWith('# ')) {
              return <h1 key={partIndex}>{part.slice(2)}</h1>;
            } else if (line.startsWith('## ')) {
              return <h2 key={partIndex}>{part.slice(3)}</h2>;
            } else if (line.startsWith('### ')) {
              return <h3 key={partIndex}>{part.slice(4)}</h3>;
            }
            return part;
          })}
        </div>
      );
    });
  };

  const renderCitations = (citations) => {
    return citations.map((citation, index) => {
      const [numbers, source] = citation.split('] Kilde: ');
      return (
        <div key={index} className="citation">
          {numbers}] Kilde:{' '}
          <span
            className="source-link"
            onClick={() => handleSourceClick(source.trim())}
          >
            {source}
          </span>
        </div>
      );
    });
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = { text: inputMessage, sender: 'user' };
    setChatMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Add a temporary message for the AI's response
    setChatMessages(prevMessages => [...prevMessages, { text: '', sender: 'assistant', isTyping: true }]);

    try {
      const token = localStorage.getItem('token');
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
      const response = await fetch(`${baseUrl}/portal/api/portalchatbot/send_message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          thread_id: threadId,
          message: inputMessage
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let botResponse = '';
      let citations = [];
      let isCitationMode = false;

      const processChunk = (chunk) => {
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') {
              return true; // Signal completion
            }
            if (data === 'CITATIONS_START') {
              isCitationMode = true;
              continue;
            }
            if (data === 'CITATIONS_END') {
              isCitationMode = false;
              continue;
            }
            if (data.startsWith('Error:')) {
              botResponse = data;
              updateChatMessages(botResponse.trim(), citations);
              return true; // Signal completion on error
            }
            if (isCitationMode) {
              citations.push(data);
            } else {
              botResponse += data + '\n';
            }
            updateChatMessages(botResponse.trim(), citations);
          }
        }
        return false; // Not completed
      };

      const updateChatMessages = (text, cits) => {
        setChatMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];
          if (lastMessage.sender === 'assistant') {
            lastMessage.text = text;
            lastMessage.formatted = formatMessage(text);
            lastMessage.citations = cits;
            lastMessage.isTyping = false;
          }
          return updatedMessages;
        });
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const isCompleted = processChunk(chunk);
        if (isCompleted) break;
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage.sender === 'assistant') {
          lastMessage.text = `Error: ${error.message}`;
          lastMessage.isTyping = false;
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Kopieret til udklipsholder');
    }, () => {
      message.error('Kunne ikke kopiere til udklipsholder');
    });
  };

  const chatbotDescription = `
    Denne chatbot kan besvare spørgsmål om regnskab og er trænet i danske regnskabsregler. 
    Bemærk venligst, at dette ikke bør bruges som eneste kilde til information, og vi er ikke 
    ansvarlige for eventuelle fejl. Det er blot et gratis værktøj, vi tilbyder vores kunder 
    til regnskabsrelaterede spørgsmål. Vi vil fortsat udvikle og forbedre vores AI-assistent så 
    denne chatbot kan give bedre og mere præcise svar.
  `;

  const sources = [
    'Bogføringsloven',
    'Revisorloven',
    'Årsregnskabsloven',
    'Virksomhedsskatteloven',
    'Personskatteloven',
    'Aktieavancebeskatningsloven',
    'Kildeskattebekendtgørelsen',
    'Virksomhedsordningen og kapitalafkastordningen',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for mindre virksomheder',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for større virksomheder',
    'Lovkrav til tidsregistrering',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-B - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-mellem - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-stor - Beierholm',
    'Ændringer til årsregnskabsloven for årsrapport 2020 - Beierholm',
    'Momsvejledning-2024 - pwc',
  ];

  return (
    <Content className="portal-support">
      <Title level={2}>Support</Title>
      
      <Card title="Kontakt os" className="contact-card">
        <Paragraph>
          <PhoneOutlined /> Telefon: 
          <Text strong><a href="tel:+4570604282"> +45 70 60 42 82</a></Text>
          <Button 
            type="text" 
            icon={<CopyOutlined />} 
            onClick={() => copyToClipboard('+4570604282')}
            className="copy-button"
          >
            Kopier
          </Button>
        </Paragraph>
        <Paragraph>
          <MailOutlined /> E-mail: 
          <Text strong><a href="mailto:support@digi-tal.dk"> support@digi-tal.dk</a></Text>
          <Button 
            type="text" 
            icon={<CopyOutlined />} 
            onClick={() => copyToClipboard('support@digi-tal.dk')}
            className="copy-button"
          >
            Kopier
          </Button>
        </Paragraph>
      </Card>

      <Card title="Support Tickets" className="tickets-card">
        <Button type="primary" style={{ marginBottom: 16 }}>Opret ny ticket</Button>
        <List
          itemLayout="horizontal"
          dataSource={dummyTickets}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={`Status: ${item.status}`}
              />
            </List.Item>
          )}
        />
      </Card>

      <Card 
        title={
          <div className="chatbot-title">
            <Avatar icon={<RobotOutlined />} className="chatbot-avatar" />
            <span>Digi-Tal Regnskabs-Chatbot</span>
            <Tooltip title={chatbotDescription} className="chatbot-tooltip">
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
            <Tooltip 
              title={
                <div>
                  <p>Kilder:</p>
                  <ul>
                    {sources.map((source, index) => (
                      <li key={index}>{source}</li>
                    ))}
                  </ul>
                </div>
              } 
              className="sources-tooltip"
            >
              <span className="sources-icon">
                <FileTextOutlined /> Kilder
              </span>
            </Tooltip>
          </div>
        } 
        className="chatbot-card"
      >
        <div className="chat-container">
          <div className="chat-messages" ref={chatContainerRef}>
            {chatMessages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender === 'assistant' ? 'ai' : msg.sender}`}>
                {msg.isTyping ? (
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  <div>{msg.formatted || msg.text}</div>
                )}
                {msg.citations && (
                  <div className="citations">
                    {renderCitations(msg.citations)}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="chat-input">
            <TextArea
              ref={inputRef}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Skriv din besked her..."
              autoSize={{ minRows: 1, maxRows: 3 }}
              disabled={isLoading}
              onPressEnter={handlePressEnter}
            />
            <Button 
              type="primary" 
              icon={<SendOutlined />} 
              onClick={handleSendMessage}
              loading={isLoading}
              disabled={!inputMessage.trim() || !threadId}
            >
              Send
            </Button>
          </div>
        </div>
      </Card>
      <Modal
        title="Citation Details"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>
            Close
          </Button>
        ]}
      >
      </Modal>
    </Content>
  );
};

export default PortalSupport;